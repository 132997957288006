<template>
    <div class="color-txt">
        <s-form @submit="addItem">
        <h2 class="head font-poppins-semibold fs-lg-4 mt-3 text-primary">Milk Disposal</h2>
        <div class="row mt-4">
            <div class="col-lg-2">
                <validated-date-picker label="Date" :rules="{required:true}" class="c-input-datepicker text-black field-required" v-model="model.milk_disposal_date" format="DD-MM-YYYY"></validated-date-picker>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-lg-2">
                <validated-file-input class="w-10r" label-text="Upload Excel File"  v-model="model.milk_disposal_file"></validated-file-input>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-lg-2">
                <validated-input class="field-required" label="Remarks" :rules="{required:true}" v-model="model.remarks"></validated-input>
            </div>
        </div>
        <div class="row mt-lg-9">
            <div class="col-12">
                <div class="fl-x fl-j-e">
                    <div class="btn-group">
                        <btn text="Save" :loading="loading" loading-text="Saving..." class="px-4"></btn>
                        <btn text="Cancel" type="reset" @click="BackToHome" design="basic-b" class="px-4"></btn>
                    </div>
                </div>
            </div>
        </div>
        </s-form>

    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../data/urls';

export default {
    name: 'MilkDisposal',
    data () {
        return {
            URL: urls.cowMilkDisposal.addEdit,
            loading: false,
            model: {
                milk_disposal_date: '',
                milk_disposal_file: '',
                remarks: ''

            }
        };
    },
    methods: {

        BackToHome () {
            this.$router.push({ path: '/ah-section/' });
        },
        async addItem () {
            try {
                this.loading = true;
                const response = await axios.form(this.URL, this.model);
                const result = response.data;
                if (result.success) {
                    this.$notify('Added Successfully', 'Success', { type: 'success' });
                    this.loading = false;
                    await this.$router.push({ path: '/cows-list/' });
                } else {
                    const errors = response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}: ${errors[key]}`;
                            this.$notify('Warning', errorMessage, { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        }
    }
};
</script>

<style scoped>

</style>
